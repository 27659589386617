.loader{
    circle:last-child {
        animation-name: spin;
        animation-duration: var( --duration, 1s );
        animation-timing-function: var( --timing, linear );
        animation-iteration-count: infinite;
        transform-origin: center;
    }
}

@keyframes spin {
    0% {
        transform: rotate( 0deg );
    }
    100% {
        transform: rotate( 360deg );
    }
}